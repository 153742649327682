import Form from "react-bootstrap/Form";
import ValidationBox from "./ValidationBox";

import styles from "./FormGroupComponent.module.css";

const FormGroupComponent = (props) => {
    const {
        successText,
        errorText,
        isRequired,
        labelText,
        success,
    } = props;
    return (
        <Form.Group className="mb-3">
            <Form.Label className={styles["form-label"]}>{labelText}</Form.Label>
            {props.children}
            {isRequired && (
                <ValidationBox success={success} messageSuccess={successText} messageFail={errorText} />
            )}
        </Form.Group>
    );
};

export default FormGroupComponent;
