import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Components from "./Components";

import styles from "./FormComponent.module.css";
const FormComponent = (props) => {
    const {
        onValidForm,
        onInValidForm,
        formName,
        formLine,
        buttonText,
        buttonVariant,
        successMessage,
        success,
        submitted,
        formComponents
    } = props.block;
    const formValues = formComponents.map(component => component.valueId);
    const requiredComponents = formComponents.filter((component) => component.isRequired);
    const compCheckIds = requiredComponents.map(component => component.valueId);
    const [form, setForm] = useState({
      isValid: false,
      firstLoad: true,
      valueIds: formValues,
      validIds: [],
      checkIds: compCheckIds,
      formValues: formValues.reduce(
        (acc, curr) => ({ ...acc, [curr]: "" }),
        {},
      ),
    });

    useEffect(() => {}, [formComponents, success, submitted]);

    const handleValidChange = (e) => {
        const { valueId, value } = e;
        const { validIds, checkIds } = form;
        if (!validIds.includes(valueId)) {
            validIds.push(valueId);
        }
        const isValid = checkIds.every((v) => validIds.includes(v));
        setForm({
         ...form,
         validIds,
         isValid,
          formValues: {
           ...form.formValues,
            [valueId]: value,
          },
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        onValidForm(form.formValues);
    };

    const handleInvalidChange = (e) => {
        const { valueId } = e;
        const { validIds, checkIds } = form;
        const index = validIds.indexOf(valueId);
        if (index > -1) {
            validIds.splice(index, 1);
        }
        const isValid = checkIds.every((v) => validIds.includes(v));
        setForm({
         ...form,
         isValid,
          validIds,
        });
    };

    const FormButton = () => {
      let disabled = !form.isValid;
      if (form.isValid && submitted) {
        disabled = true;
      }
      return (
        <Button
          variant={buttonVariant}
          type="submit"
          disabled={disabled}
          className="mt-5"
        >
          {buttonText}
        </Button>
      );
    };

    const SubmittedMessage = () => {
      if (success && submitted) {
        return (
          <div
            className="alert alert-success"
            role="alert"
          >
            <h4 className="alert-heading">Success!</h4>
            <p>{successMessage}</p>
          </div>
        );
      }
      if (submitted) {
        return (
          <div
            className="alert alert-info"
            role="alert"
          >
            <h4 className="alert-heading">In Progress...</h4>
            <p>Your message is being sent...</p>
          </div>
        );
      }
      
    };

    return (
      <Container className={styles["form-container"]}>
        <h3 className="mt-5">{formName}</h3>
        <p>{formLine}</p>
        <SubmittedMessage />
        {!success && (
          <Form onSubmit={handleFormSubmit}>
            {formComponents.map((component) =>
              Components({
                ...component,
                onValidValue: handleValidChange,
                onInvalidValue: handleInvalidChange,
              }),
            )}
          <FormButton />
          </Form>
        )}
      </Container>
    );
};

export default FormComponent;