import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false,
    mainModal: false
}

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        toggleOpen: (state) => {
            state.open = !state.open;
        },
        setMainModal: (state, action) => {
            state.mainModal = action.payload;
        },
        toggleMainModal: (state) => {
            state.mainModal =!state.mainModal;
        },
        closeOpenModal: (state) => {
            state.mainModal = false;
        }
    }
})

export const navigationSliceActions = navigationSlice.actions;

export default navigationSlice.reducer;