export const LeadActions = {
    CREATE_LEAD: "CREATE_LEAD",
    UPDATE_LEAD: "UPDATE_LEAD",
}

export function createLead(payload) { 
    return {
        type: LeadActions.CREATE_LEAD,
        payload,
    }
}

export function updateLead(payload) {
    return {
        type: LeadActions.UPDATE_LEAD,
        payload,
    }
}