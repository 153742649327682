import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import FormGroupComponent from "./FormGroupComponent";

const ButtonGroup = (props) => {
    const {
      valueId,
      onValidValue,
      onInvalidValue,
      labelText,
      successText,
      errorText,
      isRequired,
      options,
    } = props.block;


  const [selection, setSelection] = useState("");
  const [validSelection, setValidSelection] = useState(false);


  const handleSelectionChange = (e) => {
    var thisSelection = e.target.value;
    if (thisSelection === selection) { 
        setSelection("");
        if (isRequired) {
            onInvalidValue(valueId);
            setValidSelection(false);
        }
    } else {
        onValidValue({
            valueId,
            value: options.find(option => option._id === thisSelection).value,
        });
        setSelection(thisSelection);
        setValidSelection(true);
    }
  };
    const fgProps = {
      successText,
      errorText,
      isRequired,
      labelText,
      success: validSelection,
    };

    return (

        <FormGroupComponent {...fgProps}>
            <Container className="border rounded p-3">
                
                {options.map(button => {
                    const { _id, text } = button;
                    const variant = _id === selection ? "primary" : "outline-info";
                    const active = _id === selection? true : false;
                    return (
                        <Container key={_id}>
                            <Button variant={variant} active={active} className="w-100" onClick={handleSelectionChange} value={_id}>
                                {text}
                            </Button>
                        </Container>
                    )
                })}
            </Container>
        </FormGroupComponent>
    );
}

export default ButtonGroup;