import { useState } from "react";
import Form from "react-bootstrap/Form";
import validator from "validator";
import FormGroupComponent from "./FormGroupComponent";


const PhoneNumberMasked = (props) => {

    const {
      valueId,
      onValidValue,
      onInvalidValue,
      labelText,
      placeholderText,
      successText,
      errorText,
      isRequired,
    } = props.block;

    const [phone, setPhone] = useState("");
    const [validPhone, setValidPhone] = useState(false);


    const handleNumberChange = (e) => {
        var thisphone = e.target.value.replace(/[^0-9]/g, "").substring(0, 10);
        setValidPhone(false);
        var prefix = '';
        var areacode = '';
        var number = '';
        // check the length of thisphone and then add phone number formatting based on (123)-123-1234 format
        switch (thisphone.length) {
          case 0:
            break;
          case 1:
          case 2:
          case 3:
            areacode = thisphone.substring(0);
            thisphone = `(${areacode})`;
            break;
          case 4:
          case 5:
          case 6:
            areacode = thisphone.substring(0, 3);
            prefix = thisphone.substring(3);
            thisphone = `(${areacode}) ${prefix}`;
            break;
          case 7:
          case 8:
          case 9:
          case 10:
            areacode = thisphone.substring(0, 3);
            prefix = thisphone.substring(3, 6);
            number = thisphone.substring(6);
            thisphone = `(${areacode}) ${prefix}-${number}`;
            break;
          default:
            break;
        }
        if (validator.isMobilePhone(thisphone, ["en-US"])) {
          onValidValue({ valueId, value: thisphone });
          setValidPhone(true);
        } else {
          onInvalidValue(valueId);
        }
        setPhone(thisphone);
    };

    const fgProps = {
      successText,
      errorText,
      isRequired,
      labelText,
      success: validPhone,
    };
    return (
        <FormGroupComponent {...fgProps}>
            <Form.Control
                type="text"
                placeholder={placeholderText}
                value={phone}
                onChange={handleNumberChange}
                required={isRequired}
                isValid={validPhone}
            />
            
        </FormGroupComponent>
    );
};


export default PhoneNumberMasked;