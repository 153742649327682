import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { Components } from "../../../UI/Form";


const CaptureLead = (props) => {
    const { captureLead } = props;
    const formModule = {
      _id: "lead-form",
      component: "form",
      formName: "Secure Your Discount",
      formLine: "Please fill out all boxes to secure your discount",
      buttonText: "Click to secure your discount!",
      buttonVariant: "primary",
      onValidForm: captureLead,
      formComponents: [
        {
          _id: "contact-name",
          component: "text",
          valueId: "name",
          labelText: "Your Name",
          placeholderText: "Enter your name...",
          successText: "Valid name",
          errorText: "Invalid name",
          isRequired: true,
        },
        {
          _id: "contact-phone",
          component: "phone",
          valueId: "phone",
          labelText: "Phone",
          placeholderText: "Enter your phone...",
          successText: "Valid phone",
          errorText: "Invalid phone",
          isRequired: true,
        },
        {
          _id: "contact-email",
          component: "email",
          valueId: "email",
          labelText: "Email",
          placeholderText: "Enter your email...",
          successText: "Valid email",
          errorText: "Invalid email",
          isRequired: true,
        },
      ],
    };
  

    return (
      <>
        {Components(formModule)}
      </>
    );
};

export default CaptureLead;