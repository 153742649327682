import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    success: false,
    submitted: false,
    validSubmit: false,
}

const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
       
        setSubmitted: (state, action) => {
            state.submitted = action.payload;
        },
        setValidSubmitted: (state, action) => {
            state.validSubmit = action.payload;
        },
        resetForm: (state) => {
            state.success = false;
            state.submitted = false;
            state.validSubmit = false;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        }
    },
});

export const contactSliceActions = contactSlice.actions;

export default contactSlice.reducer;