import { useState } from "react";
import Form from "react-bootstrap/Form";
import validator from "validator";
import FormGroupComponent from "./FormGroupComponent";

const Text = (props) => {
    const {
      valueId,
      onValidValue,
      onInvalidValue,
      labelText,
      placeholderText,
      successText,
      errorText,
      isRequired,
    } = props.block;

    const [text, setText] = useState("");
    const [validText, setValidText] = useState(false);


    const handleTextChange = (e) => {
        var thisText = validator.escape(e.target.value);
        setValidText(false);
        setText(thisText);
        if (isRequired)
        {
          if (thisText.length > 2) {
            onValidValue({ valueId, value: thisText });
            setValidText(true);
          } else {
            onInvalidValue(valueId);
          }          
        } else {
          onValidValue({ valueId, value: thisText });
          setValidText(true);
        }
    };
    const fgProps = {
      successText,
      errorText,
      isRequired,
      labelText,
      success: validText,
    };
    return (
      <FormGroupComponent {...fgProps}>
        <Form.Control
            type="text"
            placeholder={placeholderText}
            value={text}
            onChange={handleTextChange}
            required={isRequired}
            isValid={validText}
            />                
        </FormGroupComponent>
       
    );
};

export default Text;