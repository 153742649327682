import { useState } from "react";
import Form from "react-bootstrap/Form";
import FormGroupComponent from "./FormGroupComponent";

const RadioGroup = (props) => {
  const {
    valueId,
    onValidValue,
    onInvalidValue,
    labelText,
    placeholderText,
    successText,
    errorText,
    isRequired,
    options,
  } = props.block;

  const [selection, setSelection] = useState("");
  const [validSelection, setValidSelection] = useState(false);

  const handleSelectionChange = (e) => {
    var thisSelection = e.target.value;
    if (thisSelection) {
      setValidSelection(false);
      setSelection(thisSelection);
      if (isRequired) {
        if (thisSelection !== "") {
          onValidValue({
            valueId,
            value: thisSelection,
          });
          setValidSelection(true);
        }
      } else {
        onValidValue({
          valueId,
          value: thisSelection,
        });
        setValidSelection(true);
      }
    }
  };

  const fgProps = {
    successText,
    errorText,
    isRequired,
    labelText,
    success: validSelection,
  };

  return (
    <FormGroupComponent {...fgProps}>
      <div className="border rounded p-2">
        {options.map((option) => {
          const thisSelected = validSelection && selection === option.value;
          return (
            <div
              key={option._id}
              onClick={handleSelectionChange}
              className="mb-2"
            >
              <input
                className={
                  thisSelected
                    ? "form-check-input is-valid"
                    : "form-check-input"
                }
                type="radio"
                id={`${option._id}radio`}
                value={option.value}
                defaultChecked={thisSelected}
                name={`${valueId}radiogroup`}
              />
              <label
                className="form-check-label mx-2"
                htmlFor={`${option._id}radio`}
              >
                {option.text}
              </label>
            </div>
          );
        })}
      </div>
    </FormGroupComponent>
  );
};

export default RadioGroup;
