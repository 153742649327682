import { Fragment } from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Home, Services, Contact } from './components/UI/Components';
import { Get2000 } from './components/Core/LandingPage';
import { Dashboard } from './components/UI/Layout';


const App = () => {
  let homeChildren = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/get2000",
      element: <Get2000 />,
    },
  ];

  let browserList = [
    {
      path: "/",
      element: <Dashboard />,
      children: homeChildren,
    }
  ];

  const router = createBrowserRouter(browserList);

  return (
    <Fragment>
      <RouterProvider router={router}/>
    </Fragment>
  );
};

export default App;