import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { landingPageSliceActions } from "../../../store";
import { createLead, updateLead } from "../../../store/middleware";
import { HeroSection } from "../../UI/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ExclamationOctagon } from "react-bootstrap-icons";
import ReactCompareImage from "react-compare-image";

import styles from "./Get2000.module.css";
import CaptureLead from "./Components/CaptureLead";
import QualifyLead from "./Components/QualifyLead";
import ProfileSheeet from "./Components/ProfileSheet";

const Get2000 = () => {
  const { takeAction, step, success, lead } = useSelector(
    (state) => state.landingPage,
  );
  const [isLoaded, setIsLoaded] = useState(true);
  const dispatch = useDispatch();

  if (isLoaded) {
    dispatch(landingPageSliceActions.resetForm());
    setIsLoaded(false);
  }
  const handleCTAClick = (e) => {
    dispatch(landingPageSliceActions.setTakeAction(true));
  };

  const handleCaptureLead = (e) => {
    const {
        email,
        name,
        phone
    } = e;
    dispatch(createLead({
      step: 2,
      payload: {
        email,
        name,
        phone
    }}));
  };

  const handleQualifyLead = (e) => {
    const { id } = lead;
    dispatch(updateLead({
      step: 3,
      id,
      payload: {
       homeowner: true
    }}));
  }

  const handleProfileSheet = (e) => {
    const { id, homeowner } = lead;
    dispatch(updateLead({
      step: 4,
      id,
      payload: {
       ...e,
       homeowner
    }}));
  }

  useEffect(() => {}, [success]);
  return (
    <Container>
      <HeroSection>
        {!takeAction && (
          <button
            type="button"
            className="btn btn-danger btn-lg"
            onClick={handleCTAClick}
          >
            <ExclamationOctagon  />
            <span className="px-2">Click HERE to save up to $2000</span>
            <ExclamationOctagon />
          </button>
        )}
      </HeroSection>
      <Container className={styles.outerContainer}>
        <Row className="mt-5">
          <Col>
            {!takeAction && (
              <Container>
                <Container>
                  <ReactCompareImage
                    leftImage="https://cahuxbgeea.cloudimg.io/_image_/images/before-tub-to-shower.jpg?wat=1&wat_gravity=southwest&wat_text=Before&wat_pad=100&wat_opacity=0.5&wat_color=white&wat_fontsize=100max"
                    rightImage="https://cahuxbgeea.cloudimg.io/_image_/images/after-tub-to-shower.jpg?wat=1&wat_gravity=southeast&wat_text=After&wat_pad=100&wat_opacity=0.5&wat_color=white&wat_fontsize=100max"
                  />
                </Container>
                <Container
                  className={`${styles["get-discount"]} ${styles["discount-text"]}`}
                >
                  <p className="mt-5">
                    Luxury Bath of Southern Utah has over 30 years of expertise
                    in transforming outdated bathrooms into luxurious,
                    functional spaces.
                  </p>
                  <p>
                    Our skilled and experienced team combines exquisite design
                    with quality craftsmanship.
                  </p>
                  <p>
                    From elegant fixtures to seamless acrylic surround panels
                    with Microban® protection, we create dream bathrooms
                    warranteed for life.
                  </p>
                  <p>Let’s elevate your home!</p>
                </Container>
                <Container className="text-center mt-5 mb-5 w-100">
                  <button
                    type="button"
                    className="btn btn-danger btn-lg w-100"
                    onClick={handleCTAClick}
                  >
                    <ExclamationOctagon />
                    <span className="px-2">Click HERE now to save!</span>
                    <ExclamationOctagon />
                  </button>
                </Container>
                <Container
                  className={`${styles["get-discount"]} ${styles["discount-text"]}`}
                >
                  <p>
                    Microban treated acrylic is key to having a mold and mildew
                    free bath environment, keeping your family safe and cleanup
                    easy.
                  </p>
                  <p>
                    Luxury Bath specialists have been designing and implementing
                    quality bathroom upgrades for over three decades.
                  </p>
                  <p>
                    We know how to get things done. Our “in house” installers
                    are BCI certified and qualified, ensuring your ultimate
                    satisfaction.
                  </p>
                  <p>Why wait- let’s achieve your dream bath together</p>
                </Container>
                <Container className="text-center mt-5 mb-5">
                  <button
                    type="button"
                    className="btn btn-danger btn-lg w-100"
                    onClick={handleCTAClick}
                  >
                    <ExclamationOctagon  />
                    <span className="px-2">Click NOW!</span>
                    <ExclamationOctagon  />
                  </button>
                </Container>
              </Container>
            )}
            {takeAction && step === 1 && (
              <CaptureLead captureLead={handleCaptureLead} />
            )}
            {takeAction && step === 2 && <QualifyLead qualifyLead={handleQualifyLead} />}
            {takeAction && step === 3 && <ProfileSheeet profileLoad={handleProfileSheet} />}
            {takeAction && step === 4 && <div className="h4 text-center mt-5">You're Done! We will be contacting you shortly!</div>}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Get2000;
