import { Check, XCircle } from "react-bootstrap-icons";

import styles from "./ValidationBox.module.css";

const ValidationBox = (props) => {
    const { success, messageSuccess, messageFail } = props;
    return (
        <ul className={styles["no-bullets"]}>
            {success && <li className="text-success">
                <Check /> {messageSuccess}
                </li>
            }
            {!success && <li className="text-danger"><XCircle /> {messageFail}</li>}
        </ul>
    );
};

export default ValidationBox;