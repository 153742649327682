import Form from "react-bootstrap/Form";
import { useState } from "react";
import FormGroupComponent from "./FormGroupComponent";

import { isMobile } from 'react-device-detect';


import styles from "./DropDownList.module.css";

const DropDownList = (props) => {
  const {
    valueId,
    onValidValue,
    onInvalidValue,
    labelText,
    placeholderText,
    successText,
    errorText,
    isRequired,
    options,
  } = props.block;

  const [selection, setSelection] = useState("");
  const [showPlaceholder, changeShowPlaceholder] = useState(true);
  const [validSelection, setValidSelection] = useState(false);

  const handleSelectionChange = (e) => {
    var thisSelection = e.target.value;
    setSelection(thisSelection);
    if (isRequired) {
        changeShowPlaceholder(false);
      if (thisSelection!== "") {
        onValidValue({valueId, value: thisSelection});
        setValidSelection(true);
      } else {
        onInvalidValue(valueId);
        setValidSelection(false);
      }
    } else {
      onValidValue({valueId, value: thisSelection});
      setValidSelection(true);
    }
  };

  const fgProps = {
    successText,
    errorText,
    isRequired,
    labelText,
    success: validSelection,
  };

  return (
    <FormGroupComponent {...fgProps}>
      <Form.Select
        className={styles.ddmenu}
        onChange={handleSelectionChange}
        value={selection}
        required={isRequired}
        isValid={validSelection}
      >
        {showPlaceholder && <option value="">{placeholderText}</option>}
        {options.map((option, index) => {
          return (
            <option
              key={index}
              value={option.value}
            >
              {option.text}
            </option>
          );
        })}
      </Form.Select>
    </FormGroupComponent>
  );
};

export default DropDownList;
