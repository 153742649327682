import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navigationSliceActions } from "../../../store";

const NavBar = props => {
    const { open } = useSelector(state => state.navigation);
    const dispatch = useDispatch();

    const handleOpenModals = () => {
        dispatch(navigationSliceActions.closeOpenModal());
    };

  const handleOpenMenu = () => {
    dispatch(navigationSliceActions.toggleOpen());
  };


  const closeOpenMenu = () => {
    if (open) dispatch(navigationSliceActions.setOpen(false));
  };

  return (
    <Navbar onClick={handleOpenModals} expand="lg" className="bg-body-tertiary" bg="light" data-bs-theme="light">
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={closeOpenMenu} >
          <img src="https://cahuxbgeea.cloudimg.io/_image_/logos/ls_logo2.png?w=150" alt="Luxury Bath of Southern Utah" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleOpenMenu} />
        <Navbar.Collapse id="basic-navbar-nav" in={open}>
            <Nav className="me-auto">
                <Nav.Link as={Link} to="/contact" onClick={closeOpenMenu}>
                    Contact
                </Nav.Link>
                <Nav.Link as={Link} to="/services" onClick={closeOpenMenu}>
                    Services
                </Nav.Link>
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;