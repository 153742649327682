import { takeEvery, put, call } from "redux-saga/effects";

import { ContactActions } from "../actions";
import { contactSliceActions } from "../../slices";
import axios from "axios";
const navkorconfiguration = process.env.REACT_APP_NAVKOR_CONFIGURATION;
const azureAPIKey = process.env.REACT_APP_AZURE_API_KEY;
const azureAPIHeader = process.env.REACT_APP_AZURE_API_HEADER;
const url = "/api/leads";

export function* submitForm(payload) {
    yield put(contactSliceActions.setSubmitted(true));
    const leadData = {
        ...payload.payload,
        type: 'comment-lead'
    };
    try {
        const { data } = yield call(async () => {
            return await axios.post(url, leadData, {
              headers: {
                navkorconfiguration,
                [azureAPIHeader]: azureAPIKey
              },
            });
        });
        const { status } = data;
        if (status === 'success') {
            yield put(contactSliceActions.setSuccess(true));
        } else {
            yield put(contactSliceActions.setSuccess(false));
        }
    } catch (error) {
        yield put(contactSliceActions.setSuccess(false));
    }
}

export function* contactRootSaga(){
    yield takeEvery(ContactActions.CONTACT_FORM_SUBMIT, submitForm);
}