import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { TopLevel } from "./TopLevel";
import NavBar from "./NavBar";

const Dashboard = () => {
  return (
    <Fragment>
      <NavBar />
      <TopLevel>
        <Outlet />
      </TopLevel>
    </Fragment>
  );
};

export default Dashboard;
