import React from "react";
import CommentBox from "./CommentBox";
import PhoneNumberMasked from "./PhoneNumberMasked";
import Text from "./Text";
import Email from "./Email";
import FormComponent from "./FormComponent";
import RangeComponent from "./RangeComponent";
import DropDownList from "./DropDownList";
import ButtonGroup from "./ButtonGroup";
import RadioGroup from "./RadioGroup";
import CheckBoxGroup from "./CheckBoxGroup";

const FormComponents = {
  comment: CommentBox,
  phone: PhoneNumberMasked,
  text: Text,
  email: Email,
  form: FormComponent,
  range: RangeComponent,
  dropdown: DropDownList,
  buttongroup: ButtonGroup,
  radiogroup: RadioGroup,
  checkboxgroup: CheckBoxGroup,
};

const Components = (block) => {
  // componet does exist
  if (typeof FormComponents[block.component] !== "undefined") {
    return React.createElement(FormComponents[block.component], {
      key: block._id,
      block: block,
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    {
      key: block._id,
    },
  );
};

export default Components;
