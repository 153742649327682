export const ContactActions = {
    CONTACT_FORM_SUBMIT: "CONTACT_FORM_SUBMIT",
}

export function submitContactForm(payload) {
    return {
        type: ContactActions.CONTACT_FORM_SUBMIT,
        payload,
    }
}
