import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import { HeroSection } from "../Layout";

import styles from "./Services.module.css";

const Services = () => {
    return (
      <Container>
        <HeroSection />
        <Container className={styles.outerContainer}>
          <h3 className="mt-5">Our services include:</h3>
          <Container className={styles.innerContainer}>
            <ListGroup className="mt-3">
              <ListGroup.Item>
                Free Project Consultations and Quotes
              </ListGroup.Item>
              <ListGroup.Item>
                Tub and Shower Replacement Options
              </ListGroup.Item>
              <ListGroup.Item>
                Custom Color Options for Walls and Bases
              </ListGroup.Item>
              <ListGroup.Item>
                Accessories-Grab Bars, Bench Seat, Shelving, etc.
              </ListGroup.Item>
              <ListGroup.Item>
                Accessible and Comfortable Walk-in Tubs
              </ListGroup.Item>
              <ListGroup.Item>
                Convenient Tub-to-Shower Conversions
              </ListGroup.Item>
              <ListGroup.Item>
                Efficient One-Day Bathroom Remodels
              </ListGroup.Item>
              <ListGroup.Item>
                Trained and Certified Remodeling Teams
              </ListGroup.Item>
              <ListGroup.Item>High-Quality Luxury Bath Products</ListGroup.Item>
              <ListGroup.Item>
                Flexible Financing Options for Qualified Customers
              </ListGroup.Item>
              <ListGroup.Item>Industry-Leading Warranties</ListGroup.Item>
              <ListGroup.Item>...and so much more!</ListGroup.Item>
            </ListGroup>
          </Container>
        </Container>
      </Container>
    );
};

export default Services;