import { useState } from "react";
import Form from "react-bootstrap/Form";
import FormGroupComponent from "./FormGroupComponent";

const CommentBox = (props) => {
  const {
    valueId,
    onValidValue,
    onInvalidValue,
    labelText,
    placeholderText,
    successText,
    errorText,
    isRequired,
  } = props.block;

  const [comment, setComment] = useState("");
  const [validComment, setValidComment] = useState(false);

  const handleCommentChange = (e) => {
    var thisComment = e.target.value;
    setComment(thisComment);
    if (isRequired) {
      if (thisComment.length > 10) {
        onValidValue({valueId, value: thisComment});
        setValidComment(true);
      } else {
        onInvalidValue(valueId);
        setValidComment(false);
      }
    } else {
      onValidValue({valueId, value: thisComment});
    }
  };
    const fgProps = {
      successText,
      errorText,
      isRequired,
      labelText,
      success: validComment,
    };
  return (
    <FormGroupComponent {...fgProps}>
      <Form.Control
        as="textarea"
        rows={3}
        onChange={handleCommentChange}
        placeholder={placeholderText}
        value={comment}
        required={isRequired}
        isValid={validComment}
      />
    </FormGroupComponent>
  );
};

export default CommentBox;
