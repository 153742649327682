import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { navigationSlice, contactSlice, landingPageSlice } from "./slices";

import { contactRootSaga, leadRootSaga } from './middleware';

const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
    reducer: {
        navigation: navigationSlice,
        contact: contactSlice,
        landingPage: landingPageSlice,
    },
    middleware: () => [sagaMiddleWare],
});

sagaMiddleWare.run(contactRootSaga);
sagaMiddleWare.run(leadRootSaga);

export default store;