import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    takeAction: false,
    step: 1,
    success: false,
    lead: {}
};

const landingPageSlice = createSlice({
    name: "landingPage",
    initialState,
    reducers: {
        setTakeAction: (state, action) => {
            state.takeAction = action.payload;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setLead: (state, action) => {
            state.lead = action.payload;
        },
        resetForm: (state) => {
            state.takeAction = false;
            state.step = 1;
            state.success = false;
            state.lead = {};
        }
    }
});

export const landingPageSliceActions = landingPageSlice.actions;

export default landingPageSlice.reducer;
