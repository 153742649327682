import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { submitContactForm, contactSliceActions } from "../../../store";
import { HeroSection } from "../Layout";
import { Components } from "../Form";


const Contact = () => {
  const { success, submitted, validSubmit } = useSelector(
    (state) => state.contact,
  );
  const [form, setForm] = useState({
    isValid: false,
    firstLoad: true,
  });

  const dispatch = useDispatch();

  if (form.firstLoad === true) {
    console.log('entered the first load of contact page...');
    dispatch(contactSliceActions.resetForm());
    setForm({
      ...form,
      firstLoad: false,
    });
  }

  useEffect(() => {}, [success, validSubmit, submitted]);

  const handleSubmit = (e) => {
    dispatch(submitContactForm(e));
  };

  const formModule = {
    _id: "contact-form",
    component: "form",
    formName: "Contact Us",
    formLine: "We'd love to hear from you!",
    buttonText: "Submit",
    buttonVariant: "primary",
    successMessage: "Thank you for contacting us!",
    success,
    submitted,
    onValidForm: handleSubmit,
    formComponents: [
      {
        _id: "contact-name",
        component: "text",
        valueId: "name",
        labelText: "Your Name",
        placeholderText: "Enter your name...",
        successText: "Valid name",
        errorText: "Invalid name",
        isRequired: true,
      },
      {
        _id: "contact-phone",
        component: "phone",
        valueId: "phone",
        labelText: "Phone",
        placeholderText: "Enter your phone...",
        successText: "Valid phone",
        errorText: "Invalid phone",
        isRequired: true,
      },
      {
        _id: "contact-email",
        component: "email",
        valueId: "email",
        labelText: "Email",
        placeholderText: "Enter your email...",
        successText: "Valid email",
        errorText: "Invalid email",
        isRequired: true,
      },
      {
        _id: "contact-comment",
        component: "comment",
        valueId: "comment",
        labelText: "How can we help you?",
        placeholderText: "Enter your comment or question...",
        successText: "Valid comment",
        errorText: "Missing comment or question",
        isRequired: true,
      }
    ],
  };

  return (
    <Container>
      <HeroSection />
      {Components(formModule)}
    </Container>
  );
};

export default Contact;
