import Carousel from "react-bootstrap/Carousel";

const SimpleCarousel = props => {
    const { images } = props;

    return (
        <Carousel>
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <img src={image.url} alt={image.alt} />
                    {image.title && (
                        <Carousel.Caption>
                            <h3>{image.title}</h3>
                            <p>{image.description}</p>
                        </Carousel.Caption>
                    )}
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default SimpleCarousel;