

import styles from "./HeroSection.module.css";

const HeroSection = props => {
    var { header, paragraph } = props;
    if (!header) {
        header = "Your LOCAL Solution for Bathroom Remodels";
    }
    if (!paragraph) {
        paragraph = "We provide the highest quality bathroom remodeling services";
    }

    return (
        <div className={styles.card}>
            <h1 className={styles.display4}>{header}</h1>    
            <p className={styles.paragraph}>{paragraph}</p>   
            {props.children}
        </div>
    )
};

export default HeroSection;