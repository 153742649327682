import { useState } from "react";
import Form from "react-bootstrap/Form";
import FormGroupComponent from "./FormGroupComponent";

const CheckBoxGroup = (props) => {
  const {
    valueId,
    onValidValue,
    onInvalidValue,
    labelText,
    placeholderText,
    successText,
    errorText,
    isRequired,
    options,
  } = props.block;

  const [selection, setSelection] = useState([]);
  const [validSelection, setValidSelection] = useState(false);

  const handleSelectionChange = (e) => {
    var thisSelection = e.target.value;
    if (thisSelection) {
      setValidSelection(false);
      const currentSelection = selection;
      if (currentSelection.includes(thisSelection)) {
        currentSelection.splice(currentSelection.indexOf(thisSelection), 1);
      } else {
        currentSelection.push(thisSelection);
      }
      setSelection(currentSelection);
      if (isRequired) {
        if (currentSelection.length > 0) { 
          onValidValue({
            valueId,
            value: currentSelection.length > 1 ? selection : thisSelection,
          });
          setValidSelection(true);
        } else {
          onInvalidValue(valueId);
          setValidSelection(false);
        }
      } else {
        onValidValue({
          valueId,
          value: currentSelection.length > 1 ? selection : thisSelection,
        });
        setValidSelection(true);
      }

  }
  };

  const fgProps = {
    successText,
    errorText,
    isRequired,
    labelText,
    success: validSelection,
  };

  return (
  <FormGroupComponent {...fgProps}>
   <div className="border rounded p-2">
    {options.map((option) => {
      const ischecked = selection.includes(option.value)
      const thisSelected = (validSelection && ischecked)
      const validClass = thisSelected? "form-check-input is-valid" : "form-check-input";
      return (
        <div
          key={option._id}
          onClick={handleSelectionChange}
          className="mb-2"
        >
          <input
            className={validClass}
            type="checkbox"
            id={`${option._id}checkbox`}
            value={option.value}
            defaultChecked={ischecked}
          />
          <label
            className="form-check-label mx-2"
            htmlFor={`${option._id}checkbox`}
          >
            {option.text}
          </label>
        </div>
      );
    })}
    </div>
  </FormGroupComponent>
  );
};

export default CheckBoxGroup;
