import { Fragment } from "react";
import { Link } from "react-router-dom";
import { HeroSection } from "../Layout";
import { SimpleCarousel } from "../Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./Home.module.css";

const Home = () => {
    const carouselImages = [
      {
        url: "https://cahuxbgeea.cloudimg.io/_image_/home_carousel/slide_1.png?h=500",
        alt: "Slide 1",
        title: "Wall Surround Colors",
        description: "Come see all the styles we offer",
      },
      {
        url: "https://cahuxbgeea.cloudimg.io/_image_/home_carousel/slide_2.png?h=500",
        alt: "Slide 2",
        title: "Shower Heads",
        description: "Find the perfect shower head for your bathroom",
      },
      {
        url: "https://cahuxbgeea.cloudimg.io/_image_/home_carousel/slide_3.png?h=500",
        alt: "Slide 3",
        title: "Arctic Ice"
      },
      {
        url: "https://cahuxbgeea.cloudimg.io/_image_/home_carousel/slide_4.png?h=500",
        alt: "Slide 4",
        title: "San Michelle"
      },
      {
        url: "https://cahuxbgeea.cloudimg.io/_image_/home_carousel/slide_5.png?h=500",
        alt: "Slide 5",
        title: "Linen"
      },
    ];
    return (
      <Fragment>
        <HeroSection />
        <Container fluid className={styles["section-1"]}>
        <Row className="justify-content-md-center mt-5">
            <Col>
            <img
                src="https://cahuxbgeea.cloudimg.io/_image_/images/store_front.png?w=600"
                className="img-fluid home-image"
                alt="Location"
            />
            </Col>
        </Row>
        <Row className="justify-content-md-center mt-5">
            <Col lg={true}>
            <h2>Our Location</h2>
            <div className={styles["home-intro"]}>
                <p>1812 W Sunset Blvd Ste 19, St. George, UT 84770</p>
                <p>Phone: (435) 599-8789</p>
                <p>
                We are your local bathroom remodel company dedicated to
                providing high quality services. <Link to="/contact">Contact us</Link> today for an appointment
                </p>
            </div>
            </Col>
            <Col lg={true}>
            <iframe
                title="locationMapFrame"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3181.213372901196!2d-113.62444258677333!3d37.12383837204055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80ca455e1f937025%3A0x467cd6bc9618b71!2sLuxury%20Bath%20of%20Southern%20Utah!5e0!3m2!1sen!2sus!4v1717130062408!5m2!1sen!2sus"
                className={styles.map}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            </Col>
        </Row>
        <Row className="justify-content-md-center mt-5">
            <Col>
                <SimpleCarousel images={carouselImages} />
            </Col>
        </Row>
        </Container>
      </Fragment>
    );
};

export default Home;