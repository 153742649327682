import { Components } from "../../../UI/Form";

const ProfileSheeet = (props) => {

    const { profileLoad } = props;
    const formModule = {
      _id: "profile-sheet",
      component: "form",
      formName: "Tell us about your project",
      formLine: "We're excited to hear from you!",
      onValidForm: profileLoad,
      buttonVariant: "primary",
      buttonText: "Submit to get your discount",
      formComponents: [
        {
          _id: "12345",
          component: "radiogroup",
          valueId: "yearsInHome",
          labelText: "How many years have you owned your home?",
          options: [
            {
              _id: "1",
              value: ">10",
              text: "Less than 10 years",
            },
            {
              _id: "2",
              value: "10-20",
              text: "Between 10 and 20 years",
            },
            {
              _id: "3",
              value: "20-30",
              text: "Between 20 and 30 years",
            },
            {
              _id: "4",
              value: "30-40",
              text: "Between 30 and 40 years",
            },
            {
              _id: "5",
              value: ">40",
              text: "More than 40 years",
            },
          ],
        },
        {
          _id: "23456",
          component: "text",
          valueId: "zipcode",
          labelText: "What is your zip code?",
          placeholderText: "Enter your zip code...",
          successText: "Valid zip code",
          errorText: "Invalid zip code",
          isRequired: true,
        },
        {
          _id: "34567",
          component: "radiogroup",
          valueId: "remodelReason",
          labelText: "Why are you remodeling?",
          options: [
            {
              _id: "11",
              value: "Bathroom needs repair",
              text: "Bathroom needs repair",
            },
            {
              _id: "12",
              value: "Bathroom needs an upgrade",
              text: "Bathroom needs an upgrade",
            },
            {
              _id: "13",
              value: "There is a safety concern!",
              text: "There is a safety concern!",
            },
            {
              _id: "14",
              value: "Other",
              text: "Other",
            },
          ],
        },
        {
          _id: "34568",
          component: "radiogroup",
          valueId: "remodelBudget",
          labelText: "What is your budget?",
          options: [
            {
              _id: "111",
              value: "$10K",
              text: "About $10,000",
            },
            {
              _id: "121",
              value: "$10K - $30K",
              text: "Between $10,000 and $30,000",
            },
            {
              _id: "131",
              value: "$30K - $50K",
              text: "Between $30,000 and $50,000",
            },
            {
              _id: "141",
              value: "At least $50K",
              text: "Over $50,000",
            },
          ],
        },
        {
          _id: "34569",
          component: "radiogroup",
          valueId: "remodelTimeframe",
          labelText: "When do you want to start?",
          options: [
            {
              _id: "1111",
              value: "ASAP",
              text: "As soon as possible!",
            },
            {
              _id: "1211",
              value: "1 month",
              text: "In about 1 month",
            },
            {
              _id: "1311",
              value: "3-6 Months",
              text: "Between 3 and 6 months",
            },
            {
              _id: "1411",
              value: "<1 year",
              text: "Within next 12 months",
            },
            {
              _id: "14111",
              value: "just looking",
              text: "I'm just looking",
            },
          ],
        },
      ],
    };

    return (
        <>
      {Components(formModule)}
        </>
    );
};

export default ProfileSheeet;