import { useState } from "react";
import Form from "react-bootstrap/Form";
import validator from "validator";
import FormGroupComponent from "./FormGroupComponent";

const Email = (props) => {
    const {
      valueId,
      onValidValue,
      onInvalidValue,
      labelText,
      placeholderText,
      successText,
      errorText,
      isRequired,
    } = props.block;

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);


    const handleEmailChange = (e) => {
        var thisEmail = e.target.value;
        setEmail(thisEmail);
        setValidEmail(false);
        if (isRequired) {
            if (!validator.isEmail(thisEmail)) {
                onInvalidValue(valueId);
                setValidEmail(false);
            } else {
                onValidValue({valueId, value: thisEmail});
                setValidEmail(true);
            }
        } 
    };

    const fgProps = {
      successText,
      errorText,
      isRequired,
      labelText,
      success: validEmail,
    };
    return (
        <FormGroupComponent {...fgProps} >
            <Form.Control
                type="email"
                placeholder={placeholderText}
                value={email}
                onChange={handleEmailChange}
                required={isRequired}
                isValid={validEmail}
                />

        </FormGroupComponent>
    );
};

export default Email;