import { takeEvery, put, call } from "redux-saga/effects";

import { LeadActions } from "../actions";
import { landingPageSliceActions } from "../../slices";
import axios from "axios";

const navkorconfiguration = process.env.REACT_APP_NAVKOR_CONFIGURATION;
const azureAPIKey = process.env.REACT_APP_AZURE_API_KEY;
const azureAPIHeader = process.env.REACT_APP_AZURE_API_HEADER;
const url = "/api/leads";

export function* createLead({payload}) {
    yield put(landingPageSliceActions.setStep(payload.step));
    const { name, email, phone } = payload.payload;
    const leadData = {
        name,
        email,
        phone,
        type: 'landing-page-lead'
    };
    try {
        const { data } = yield call(async () => {
            return await axios.post(url, leadData, {
              headers: {
                navkorconfiguration,
                [azureAPIHeader]: azureAPIKey,
              },
            });
        });
        const { status } = data;
        if (status === 'success') {
            yield put(landingPageSliceActions.setLead(data.data));
        } else {
            yield put(landingPageSliceActions.setSuccess(false));
        }
    } catch (error) {
        yield put(landingPageSliceActions.setSuccess(false));
    }
};

export function* updateLead({payload}) {
    yield put(landingPageSliceActions.setStep(payload.step));
    const leadData = {
      ...payload.payload,
    };
    try {
        const { data } = yield call(async () => {
            return await axios.put(`${url}/${payload.id}`, leadData, {
              headers: {
                navkorconfiguration,
                [azureAPIHeader]: azureAPIKey,
              },
            });
        });
        const { status } = data;
        if (status === 'success') {
            yield put(landingPageSliceActions.setLead(data.data));
        } else {
            yield put(landingPageSliceActions.setSuccess(false));
        }
    } catch (error) {
        yield console.error('Error submitting lead to database', error);
        yield put(landingPageSliceActions.setSuccess(false));
    }
};

export function* leadRootSaga(){
    yield takeEvery(LeadActions.CREATE_LEAD, createLead);
    yield takeEvery(LeadActions.UPDATE_LEAD, updateLead);
}