import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import FormGroupComponent from "./FormGroupComponent";


const RangeComponent = (props) => {
    const {
      valueId,
      onValidValue,
      onInvalidValue,
      labelText,
      step,
      minValue,
      successText,
      errorText,
      isRequired,
      labels,
    } = props.block;

    const [answer, setAnswer] = useState(labels[0]);
    const [validAnswer, setValidAnswer] = useState(false);

    const labelsLength = labels.length;
    const max = labelsLength - 1;
    const firstValue = labels[0];
    const lastValue = labels[labelsLength - 1];
    var value = 0;



    const handleOnChange = (e) => {
        var thisValue = e.target.value;
        setAnswer(labels[thisValue]);
        if (isRequired) {
            if (thisValue >= minValue) {
                onValidValue({valueId, value: answer});
                setValidAnswer(true);
            } else {
                onInvalidValue(valueId);
                setValidAnswer(false);
            }
        } else {
            onValidValue({valueId, value: answer});
        }
    };

    const fgProps = {
      successText,
      errorText,
      isRequired,
      labelText,
      success: validAnswer,
    };

    return (
        <FormGroupComponent {...fgProps}>
            <Container>
                <Form.Label>Answer: {answer}</Form.Label>
            </Container>
             <Container fluid="true" className="border rounded p-2">
                <Row>
                    <Col className="text-start">
                        <Form.Label>{firstValue}</Form.Label>
                    </Col>

                    <Col className="text-end">
                        <Form.Label>{lastValue}</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Form.Range
                        onChange={handleOnChange}
                        step={step}
                        max={max}
                        min={0}
                        defaultValue={value}
                    />
                    <Form.Text id="rangeHelperText" muted>
                        Move the slider to adjust your answer.
                    </Form.Text>

                    </Col>
                </Row>
             </Container>
        </FormGroupComponent>
    );
};

export default RangeComponent;