import { Container, Row, Col } from "react-bootstrap"

//import classes from "./TopLevel.module.css"

const TopLevel = props => {
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col>
                    {props.children}
                </Col>
            </Row>
        </Container>
    );
}

export default TopLevel;