import Button from "react-bootstrap/Button";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const QualifyLead = (props) => {
    const { qualifyLead } = props;
    const [nonOwner, setIsQualified] = useState(false);

    const handleOwnerClick = (e) => {
        qualifyLead(e);
    }

    const handleNonOwnerClick = (e) => {
        setIsQualified(true);
    }

    const changeAnswerHandler = (e) => {
        setIsQualified(false);
    }
    return (        
        <Container>
            {!nonOwner && (
                <Container>
                    <Row>
                    <div className="text-center mb-2 h2">
                        Are you the homeowner or authorized agent?</div>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <Button onClick={handleOwnerClick}>Yes</Button>
                        </Col>
                        <Col>
                            <Button onClick={handleNonOwnerClick}>No</Button>
                        </Col>
                    </Row>
                </Container>
            )}
            {nonOwner && (
                <Container>
                    <Row>
                        <div className="text-center h4">
                            We're sorry.  We need to speak to the homeowner on bathroom remodels.  Please have the homeowner contact us.
                        </div>
                    </Row>
                    <Row>
                        <Col className="text-center mt-3">
                            <Button onClick={changeAnswerHandler}>Change Answer?</Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Container>
    );
};

export default QualifyLead;